// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-functions-js": () => import("./../../../src/pages/functions.js" /* webpackChunkName: "component---src-pages-functions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index_old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-opinions-js": () => import("./../../../src/pages/opinions.js" /* webpackChunkName: "component---src-pages-opinions-js" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-termsofuse-js": () => import("./../../../src/pages/termsofuse.js" /* webpackChunkName: "component---src-pages-termsofuse-js" */)
}

